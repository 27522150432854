import React from "react";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Products from "~components/products/products";
import TypeAnimation from 'react-type-animation';

const ContentPage = () => (
    <Layout>
        <SEO title="Hire a CMO "/>
        <section className="page-heading">
            <div className="container">
                <TypeAnimation
                    cursor={false}
                    sequence={['Hire', 1500, 'Hire a', 1500, 'Hire a CMO', 1500]}
                    className={'title'}
                    wrapper="h1"
                />
            </div>
        </section>
        <section className="">
            <div className="container">
                <Products>
                    <div title="3 Month Launch Project " price="$15,000" id="cmo-3">
                        <p>Have an idea, but need to get it off the ground simply, quickly and for a fair monetary
                            value? Our Launch Package is designed to give you the most important pieces to build
                            momentum for your business. We got you! </p>
                        <p>Not every brand and business needs the weight of a full agency behind their marketing –
                            sometimes they just need quick creative support – but from really talented, highly
                            experienced, bright people. No flakey contractors, no missed deadlines, no radio silence, no
                            egos. You pick the service you want. We do it. <br/>You get what you want. </p>
                    </div>
                    <div title="6 Month Growth Project" price="$30,000" id="cmo-6">
                        Looking to take your business to the next level; but unsure of where to invest your marketing
                        budget? Our Grow Package is specifically tailored to get your idea or business flourishing. We
                        are here to guide you!
                    </div>
                    <div title="12 Month Build Project" price="$30,000" id="cmo-12">
                        Ready to turn your small idea or business into something HUGE! Our Build Package is all about
                        going big and turning your small business into something even bigger.
                    </div>
                </Products>
            </div>
        </section>
    </Layout>
)

export default ContentPage;
